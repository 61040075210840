import React from "react";
import Accordion from "./Accordion";

const Profile = (name, title, img, bio, linkedin) => {
  return (
    <div className="max-w-[925px]">
      <img src={img}></img>
      <div
        className="text-2xl lg:text-4xl mt-16"
        style={{ fontFamily: "SharpSans-Semibold" }}
      >
        {name}
      </div>
      <div
        className="text-xl lg:text-2xl mt-5"
        style={{ fontFamily: "SharpSans-Medium" }}
      >
        {title}
      </div>
      {Accordion(bio, linkedin)}
    </div>
  );
};

export default function Team() {
  return (
    <div className="p-4 lg:p-16 relative overflow-x-clip">
      <div className="flex pt-36 mb-4" id="Team">
        <div
          style={{ fontFamily: "SharpSans-Medium" }}
          className="tracking-widest"
        >
          TEAM
        </div>
        <div className="flex justify-center content-center h-auto pl-3 lg:pl-10 ">
          <div className="border-b-2 w-28 lg:w-72 h-[50%] border-[#F15A22]"></div>
        </div>
      </div>
      <div
        className="text-4xl lg:text-7xl lg:leading-tight"
        style={{ fontFamily: "SpaceGroteskLight" }}
      >
        Deep domain expertise & <br></br>operating experience
      </div>
      <div
        className="lg:text-2xl lg:leading-tight my-14 2xl:w-[30%]"
        style={{ fontFamily: "SharpSans-Medium" }}
      >
        Corner Point is co-led by Rashmi Gopinath and Kristin Baker Spohn, who
        have a combined 45 years of experience building and investing in
        enterprise and health technology.
      </div>
      <div className="flex flex-wrap gap-28 mt-32 justify-center min-h-[1022px] mb-36">
        {Profile(
          "Rashmi Gopinath",
          "Co-founder and Managing Partner, BAM Corner Point",
          require("../assets/team/rashmi.png"),
          `Rashmi Gopinath was most recently General Partner and Head of Enterprise Software at B Capital where she led the firm’s global enterprise software practice.
          Previously, she was Managing Director and founding investor at M12, Microsoft’s venture fund, where she led investments globally in enterprise software.  Prior to M12, Rashmi was an Investment Director with Intel Capital.
          She also held operating go-to-market executive roles at BlueData (acquired by HPE), Couchbase (NASDAQ: BASE) and product roles at Oracle and GE. `,
          "https://www.linkedin.com/in/rashmigopinath/",
        )}
        {Profile(
          "Kristin Baker Spohn",
          "Co-founder and Managing Partner, BAM Corner Point",
          require("../assets/team/kristin.png"),
          `Kristin was previously General Partner at Charles River Ventures and Partner at Social Capital, where she led venture investments in enterprise and health technology companies.
          As an Operator, she led go-to-market at Collective Health and scaled the company through late growth stages as Chief Commercial Officer.
          She previously led Business Development and Finance functions at Castlight Health from Series A through IPO (NYSE: CSLT).
          Kristin launched her career at Goldman Sachs in investment banking and is currently a Lecturer in Management at Stanford Graduate School of Business.`,
          "https://www.linkedin.com/in/kristin-baker-spohn-8953762/",
        )}
        {Profile(
          "Nickhil Nabar",
          "Head of Data Science & AI, Private Investments",
          require("../assets/team/nickhil.png"),
          `Nickhil joined BAM in May 2022 following 4 years at Theta, an AI-enabled software platform for data-driven GTM optimization, where he scaled the software, research, and product organizations from 0 to 1 as the Head of Data Science & Engineering. 
          Nickhil previously held various Software Engineer and Quantitative Developer roles at Citadel (Equities, Global Quantitative Strategies) and Citadel Securities (ETF Market Making). 
          He received his B.S. Economics (Statistics, Finance) from Wharton and his B.S. Engineering (Computer Science) via the M&T Program at the University of Pennsylvania.`,
          "https://www.linkedin.com/in/nnabar/",
        )}
        {Profile(
          "Stephanie Phen",
          "Investor, BAM Corner Point",
          require("../assets/team/stephanie.png"),
          `Stephanie is focused on enterprise tech investments at BAM Corner Point. Previously, Stephanie worked at TCV and focused on software technology companies.
          Stephanie began her career in investment banking at Goldman Sachs in NYC. She received her Bachelor of Science in Industrial Labor Relations from Cornell University.`,
          "https://www.linkedin.com/in/stephaniephen/",
        )}
        {Profile(
          "Libbi Klearman",
          "Executive Assistant, BAM Corner Point",
          require("../assets/team/libbi.png"),
          `Libbi Klearman is an Executive Assistant on the BAM Corner Point Team, based in San Francisco. She joined BAM in August 2024 from B Capital, where she was a Senior Executive Assistant supporting Rashmi Gopinath in her role as the Head of Enterprise Software. 
          Prior to her time at B Capital, Libbi worked at Temasek supporting the Joint Head of Global TMT. Before working as an Executive Assistant, she worked as a Registered Sales Assistant in both retail and institutional investments. 
          Libbi earned a BA of Psychology from UC Santa Cruz.`,
          "https://www.linkedin.com/in/libbi-klearman-04976184",
        )}
      </div>
      <img
        src={require("../assets/bg_pixels_right.png")}
        className="right-[0px] top-[350px] lg:top-[-80px] pixels"
      />
    </div>
  );
}
