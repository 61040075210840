import React from "react";
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

export default function CookieConsentBanner({
  cookieName,
  visible,
  onAccept,
  onDecline,
}) {
  if (!visible) return <></>;

  return (
    <CookieConsent
      location="bottom"
      flipButtons
      enableDeclineButton
      onAccept={onAccept}
      onDecline={onDecline}
      buttonText={
        <p
          className="border-2 border-white py-4 px-[24px] rounded-md"
          style={{ "font-family": "SharpSans-Medium" }}
        >
          Accept
        </p>
      }
      declineButtonText={
        <p
          className="border-2 border-white py-4 px-[24px] rounded-md"
          style={{ "font-family": "SharpSans-Medium" }}
        >
          Reject
        </p>
      }
      style={{
        background: "rgba(0,113,254,0.9)",
        justifyContent: "flex-center",
        placeItems: "center",
      }}
      buttonStyle={{ color: "white", background: "none" }}
      declineButtonStyle={{ color: "white", background: "none" }}
      cookieName={cookieName}
    >
      <div className="text-center">
        <p
          className="mt-2 lg:text-lg mb-6"
          style={{ "font-family": "SharpSans-Medium" }}
        >
          Our website uses cookies to give you the best browsing experience. By
          using this website, you consent to our use of cookies.
          <br />
          For more information, please read our{" "}
          <Link className="font-semibold" to="/privacy-policy">
            Privacy Policy
          </Link>
          .
        </p>
      </div>
      {}
    </CookieConsent>
  );
}
