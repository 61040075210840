import { useState } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const navigation = [
  { name: "About", href: "#About" },
  { name: "Team", href: "#Team" },
  { name: "Contact", href: "#Contact", border: true },
];

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="text-white p-4 lg:p-16">
      <nav
        aria-label="Global"
        className="mx-auto flex items-center justify-between"
      >
        <Link to="/">
          <span className="sr-only">BAM Corner Point</span>
          <img
            alt=""
            src={require("../assets/logo.svg").default}
            className="w-[200px] lg:w-[298px]"
          />
        </Link>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12 text-white items-center">
          {navigation.map((item) => (
            <HashLink
              key={item.name}
              smooth
              to={`/${item.href}`}
              className={`${item.border ? "border-2 border-white py-4 px-[24px] rounded-md" : ""}`}
              style={{ fontFamily: "SharpSans-Medium" }}
            >
              {item.name}
            </HashLink>
          ))}
        </div>
      </nav>
      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="lg:hidden"
      >
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-[#0071FE] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">BAM Corner Point</span>
              <img
                alt=""
                src={require("../assets/cp_icon_white.png")}
                className="h-8 w-auto"
              />
            </Link>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <HashLink
                    key={item.name}
                    to={`/${item.href}`}
                    className="-mx-3 block px-3 py-2 font-semibold text-white"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {item.name}
                  </HashLink>
                ))}
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}
