import React from "react";
import Hero from "../Components/Hero";
import About from "../Components/About";
import Team from "../Components/Team";
import Contact from "../Components/Contact";
import Footer from "../Components/Footer";

export default function Home() {
  return (
    <>
      <Hero />
      <About />
      <Team />
      <Contact />
      <Footer />
    </>
  );
}
