import React from "react";

export default function Contact() {
  return (
    <div className="bg-[#D9D9D926] p-4 lg:p-16 bg-opacity-15">
      <div className="pt-36 mb-80" id="Contact">
        <div className="flex mb-4">
          <div
            style={{ fontFamily: "SharpSans-Medium" }}
            className="tracking-widest text-[#0071FE]"
            id="Contact"
          >
            CONTACT
          </div>
          <div className="flex justify-center content-center h-auto pl-3 lg:pl-10 ">
            <div className="border-b-2 w-28 lg:w-72 h-[50%] border-[#6AC4D0]"></div>
          </div>
        </div>
        <div
          style={{ fontFamily: "SpaceGroteskLight" }}
          className="text-4xl lg:text-7xl lg:leading-tight"
        >
          Building transformative <br></br>enterprises of tomorrow 
        </div>
        <div className="my-14">
          <a
            className="cursor-pointer text-xl lg:text-4xl text-[#0071FE]"
            href="mailto:contact@bamcornerpoint.com"
            style={{ fontFamily: "SharpSans-Medium" }}
          >
            contact@bamcornerpoint.com
          </a>
        </div>
      </div>
    </div>
  );
}
