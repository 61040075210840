import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export default function Footer() {
  return (
    <div className="bg-[#0071FE] p-4 lg:p-16">
      <div className="lg:flex lg:justify-between pt-32">
        <HashLink to="/#">
          <img src={require("../assets/logo.svg").default}></img>
        </HashLink>
      </div>
      <div
        className="text-white text-sm mt-20"
        style={{ fontFamily: "SharpSans-Medium" }}
      >
        <div className="flex flex-wrap gap-6 mb-9">
          <Link to="/user-agreement">User Agreement</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/fraudulent-schemes-warning">
            Fraudulent Schemes Warning
          </Link>
        </div>
        <div className="">©2025 BAM Corner Point. All rights reserved.</div>
      </div>
    </div>
  );
}
