import React from "react";

export default function About() {
  return (
    <div className="p-4 lg:p-16 relative overflow-x-clip">
      <div className="flex pt-36 mb-4" id="About">
        <div
          style={{ fontFamily: "SharpSans-Medium" }}
          className="tracking-widest"
        >
          BAM CORNER POINT
        </div>
        <div className="flex justify-center content-center h-auto pl-3 lg:pl-10 ">
          <div className="border-b-2 w-28 lg:w-72 h-[50%] border-[#FCCA16]"></div>
        </div>
      </div>
      {/* <div
        className="text-4xl lg:text-7xl lg:leading-tight"
        style={{ fontFamily: "SpaceGroteskLight" }}
      >
        Investing in early-growth <br></br>enterprise technology
      </div> */}
      <div className="w-full flex mt-16">
        <div
          className="max-w-[1100px] lg:text-2xl lg:leading-loose"
          style={{ fontFamily: "SharpSans-Medium" }}
        >
          BAM Corner Point is an early growth, enterprise technology focused
          venture capital firm built in partnership with Balyasny Asset
          Management. Our thesis-driven and growth focused mindset is
          underpinned by data science and AI paired with public equities
          expertise. We invest in early growth (predominantly series B through
          D) enterprise technology companies across AI, data infrastructure,
          health tech, application software, cybersecurity, DevOps, and cloud
          infrastructure.
        </div>
      </div>
      <img
        src={require("../assets/bg_pixels_left.png")}
        className="top-[-80px] left-[0px] pixels"
      />
    </div>
  );
}
