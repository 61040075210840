import React, { useState } from "react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

const Accordion = (bio, linkedin) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="">
      <div
        className={`transition-max-height duration-500 ease-in-out ${
          isOpen ? "max-h-[1000px]" : "max-h-0"
        } overflow-hidden`}
      >
        <div className="">
          <p
            className="mt-2 lg:text-lg mb-6"
            style={{ fontFamily: "SharpSans-Medium" }}
          >
            {bio}
          </p>
          <a
            className="bg-[#0071FE] rounded-full w-12 h-12 flex items-center justify-center"
            href={linkedin}
            target="_blank"
          >
            <img
              src={require("../assets/icons/linkedin.png")}
              className="h-6 bg-[#0071FE]"
            />
          </a>
        </div>
      </div>
      <div
        className="flex text-[#0071FE] items-center mt-10 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div
          className="lg:text-lg mr-4"
          style={{ fontFamily: "SharpSans-Bold" }}
        >
          {isOpen ? "HIDE BIO" : "VIEW BIO"}
        </div>
        {isOpen ? (
          <ArrowLeftIcon className="h-5" />
        ) : (
          <ArrowRightIcon className="h-5" />
        )}
      </div>
    </div>
  );
};

export default Accordion;
