import React from "react";
import Header from "./Header";
import "../App.css";

export default function Hero() {
  const slides = (repeat) => {
    const slides = [];
    const logos = (
      <>
        <img
          src={require("../assets/company_logos/data_robot.svg").default}
          className="inline-block h-[30px] lg:h-[50px] mx-[30px] lg:mx-[80px]"
        />
        <img
          src={require("../assets/company_logos/label_box.svg").default}
          className="inline h-[30px] lg:h-[50px] mx-[30px] lg:mx-[80px]"
        />
        <img
          src={require("../assets/company_logos/poolside.svg").default}
          className="inline h-[30px] lg:h-[50px] mx-[30px] lg:mx-[80px]"
        />
        <img
          src={require("../assets/company_logos/sync.svg").default}
          className="inline h-[30px] lg:h-[50px] mx-[30px] lg:mx-[80px]"
        />
        <img
          img
          src={require("../assets/company_logos/yalo.svg").default}
          className="inline h-[30px] lg:h-[50px] mx-[30px] lg:mx-[80px]"
        />
      </>
    );
    for (let i = 0; i < repeat; i++) slides.push(logos);
    return slides;
  };
  return (
    <>
      <div className="Hero flex flex-col relative overflow-x-clip h-[580px] lg:h-[1166px]">
        <Header />
        <div className="mt-28 lg:mt-40 flex sm:max-w-[540px] lg:max-w-[1400px]">
          <div
            className="text-4xl lg:text-8xl text-white pl-4 lg:pl-16"
            style={{ fontFamily: "SpaceGroteskRegular" }}
          >
            Investing in visionary technology entrepreneurs building
            transformative enterprises
          </div>
          <img
            src={require("../assets/cp_icon.png")}
            className="h-[814px] w-auto absolute left-[400px] top-[100px] lg:left-[930px] lg:top-[190px]"
          />
        </div>
        {/* <div className="grow"></div>
        <div className="logos">
          <div className="logos-slide">{slides(15)}</div>
        </div> */}
      </div>
    </>
  );
}
