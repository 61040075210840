import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

export default function Markdown({ md }) {
  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    fetch(md)
      .then((response) => response.text())
      .then((text) => setMarkdown(text));
  }, []);

  return (
    <div>
      <div className="Header">
        <Header></Header>
      </div>
      {markdown && (
        <>
          <div className="flex justify-center my-28 p-4 lg:p-16">
            <ReactMarkdown
              children={markdown}
              className="prose lg:prose-lg xl:prose-xl"
              remarkPlugins={[remarkGfm]}
              components={{
                h1: ({ node, ...props }) => (
                  <h1
                    style={{ color: "#0071FE", fontFamily: "SharpSans-Bold" }}
                    {...props}
                  />
                ),
              }}
            />
          </div>
          <Footer />
        </>
      )}
    </div>
  );
}
